// {Dashboard.tsx}

import { Box } from '@mui/material';
import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Dashboard as DashboardContent } from 'quil';
import { useNavigate } from 'react-router-dom';
// import useStyles from './styles';
// import TEST_ID from './constants';

export interface DashboardProps {
  // Props
}

export default function Dashboard() {
  // const { classes, cx } = useStyles();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const firstName = activeAccount?.name?.split(' ')[0];
  const navigate = useNavigate();

  const handleNav = (navTo: string) => {
    navigate(navTo);
  };

  return (
    <Box>
      <DashboardContent userFirstName={firstName || ''} onNavTo={handleNav} />
    </Box>
  );
}
