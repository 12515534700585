// {Dashboard.tsx}

import { Box } from '@mui/material';
import React from 'react';
import { useMsal } from '@azure/msal-react';
import { closeAssay, closeExperiment, Dashboard as DashboardContent, ResourceType, TrackedAction, useAppDispatch } from 'quil';
import { useNavigate } from 'react-router-dom';
// import useStyles from './styles';
// import TEST_ID from './constants';

export interface DashboardProps {
  // Props
}

export default function Dashboard() {
  // const { classes, cx } = useStyles();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const firstName = activeAccount?.name?.split(' ')[0];
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNav = (navTo: string) => {
    navigate(navTo);
  };

  const handleNavToRecent = (action: TrackedAction) => {
    
    if (action.resourceType === ResourceType.Experiment){
      dispatch(closeAssay());
      dispatch(closeExperiment());
      navigate('experiments/editor', { state: { experimentId: action.resourceId } });
    }
    else if (action.resourceType === ResourceType.Assay){
      dispatch(closeAssay());
      navigate('assays/editor', { state: { protocolId: action.resourceId } });
    } 
  }

  return (
    <Box>
      <DashboardContent userFirstName={firstName || ''} onNavTo={handleNav} onNavToRecentAction={handleNavToRecent} />
    </Box>
  );
}
